import React from 'react';
import PropTypes from 'prop-types';
import FieldActionButton from '../FieldActionButton/FieldActionButton';

const AuthenticateButton = ({
  authenticateUrl,
  carrierIntegrationName,
  queryParameters = {},
  preAuthenticate,
}) => {
  const [isProcessing, setIsProcessing] = React.useState(false);

  const buildUrlWithParameters = (url, parameters) => {
    const paramsArray = Object.values(parameters);
    const dynamicPath = paramsArray.length > 0 ? `/${paramsArray.join('/')}` : '';
    return `${url}${dynamicPath}`;
  };

  const handleClick = async () => {
    if (preAuthenticate) {
      setIsProcessing(true);
      const isValid = await preAuthenticate();
      setIsProcessing(false);
      if (!isValid) return;
    }

    window.location.href = buildUrlWithParameters(authenticateUrl, queryParameters);
  };

  const isDisabled = !authenticateUrl || isProcessing;

  const tooltip = (() => {
    if (isProcessing) return 'Saving...';
    if (!authenticateUrl) return 'No Authentication URL set';
    return `Authenticate with ${carrierIntegrationName}`;
  })();

  return (
    <div>
      <FieldActionButton
        onClick={!isProcessing ? handleClick : undefined}
        tooltip={tooltip}
        buttonText="Authenticate"
        disabled={isDisabled}
      >
        Authenticate
      </FieldActionButton>
    </div>
  );
};

AuthenticateButton.propTypes = {
  authenticateUrl: PropTypes.string.isRequired,
  carrierIntegrationName: PropTypes.string.isRequired,
  queryParameters: PropTypes.shape({}).isRequired,
  preAuthenticate: PropTypes.func.isRequired,
};

export default AuthenticateButton;
