/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import React from 'react';
import InputWithValidation from 'components/InputWithValidation';
import IconButton from 'components/IconButton/IconButton';
// eslint-disable-next-line import/no-extraneous-dependencies
import PencilIcon from '@icons/material/PencilIcon';
import CrossIcon from 'images/cross.svg';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const SecureInput = ({ integrationProperty, toggleReadOnly, handleChange }) => {
  const getInputType = () => {
    if (integrationProperty.readOnly) return 'password';
    return integrationProperty.fieldType === 'text_area' ? 'textarea' : 'text';
  };

  const inputType = getInputType();

  return (
    <InputWithValidation
      type={inputType}
      name={`integrationProperties.${integrationProperty.fieldName}`}
      value={integrationProperty.fieldValue}
      label={integrationProperty.fieldTitle}
      onChange={handleChange}
      required
      action={(
        <IconButton onClick={(ev) => {
          toggleReadOnly(integrationProperty);
          ev.currentTarget?.parentNode?.previousSibling?.focus();
        }}
        >
          {integrationProperty.readOnly ? (
            <PencilIcon
              className={styles.icon}
              data-tip="Edit"
              hidden={!integrationProperty.readOnly}
            />
          ) : (
            <CrossIcon
              className={styles.icon}
              height="20"
              data-tip="Revert changes"
              hidden={integrationProperty.readOnly}
            />
          )}
        </IconButton>
      )}
      disabled={integrationProperty.readOnly}
      tabIndex={integrationProperty.readOnly ? '-1' : null}
    />
  );
};

SecureInput.propTypes = {
  integrationProperty: PropTypes.shape({
    readOnly: PropTypes.bool.isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fieldTitle: PropTypes.string.isRequired,
  }).isRequired,
  toggleReadOnly: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SecureInput;
