import React from 'react';
import AuthenticateButton from 'components/AuthenticateButton/AuthenticateButton';
import SelectWithValidation from 'components/SelectWithValidation';
import InputWithValidation from 'components/InputWithValidation';

export const isSecure = (integrationProperty) => integrationProperty
  .validationRules.filter(z => z.ruleType === 'secure').length === 1;

export const isRequired = (integrationProperty) => integrationProperty.validationRules
  .filter(rule => rule.ruleType === 'required').length === 1;

export const isRange = integrationProperty => integrationProperty
  .validationRules.filter(z => z.ruleType === 'range').length === 1;

export const options = integrationProperty => integrationProperty.validationRules.filter(z => z.ruleType === 'range')[0]
  .acceptableValues.map(z => ({ label: z, value: z }));

export const renderAuthenticateButton = ({
  integrationProperty,
  carrierIntegrationName,
  configurationId,
  customerId,
  preAuthenticateCallback,
  setFromAuthenticateButton,
}) => {
  if (!integrationProperty?.fieldUrl) return null;

  return (
    <AuthenticateButton
      authenticateUrl={integrationProperty.fieldUrl}
      carrierIntegrationName={carrierIntegrationName}
      queryParameters={{
        configurationId,
        customerId,
      }}
      preAuthenticate={() => {
        setFromAuthenticateButton(true);
        return preAuthenticateCallback()
          .finally(() => setFromAuthenticateButton(false));
      }}
    />
  );
};

export const renderInputWithValidation = (integrationProperty, config) => {
  if (integrationProperty.fieldType === 'text_area') {
    return (
      <InputWithValidation
        inputTag="textarea"
        required={isRequired(integrationProperty)}
        {...config}
      />
    );
  }

  return isRange(integrationProperty)
    ? (
      <SelectWithValidation
        required
        options={options(integrationProperty)}
        {...config}
      />
    )
    : (
      <InputWithValidation
        required={isRequired(integrationProperty)}
        type="text"
        {...config}
      />
    );
};
